'use client'
import React from 'react'
import { If, Then, Else } from 'react-if'
import './consents-checkboxes.scss'
import PropTypes from 'prop-types'
import { Bem } from '@common/utils'
import { noop, extend } from 'lodash'
import Checkbox from '@components/checkbox/index'

const cn = new Bem({
  name: 'consents-checkboxes',
  prefix: 'pfx-'
})

class ConsentsCheckboxes extends React.Component {
  defaultValues = {
    age: false,
    terms: false,
    marketing: false
  }

  constructor (props) {
    super(props)
    this.consents = extend({}, this.defaultValues)
  }

  inputValueChanged (field, value) {
    this.consents[field] = value
    this.props.onChange(extend({}, this.consents))
  }

  componentDidMount() {
    if (this.props.eduMode) {
      this.consents['terms'] = true
      this.props.onChange(extend({}, this.consents))
    }
  }

  render () {
    const { eduMode } = this.props
    return (
      <div className={cn()}>
        <div className={cn('terms')}>
          <If condition={eduMode}>
            <Then>
              <p className={cn('bottom-text')}>By proceeding, you agree to our <a href='https://soundation.com/terms-of-service' target='_blank'>Terms of Service</a> and <a href='https://soundation.com/privacy-policy' target='_blank'>Privacy Policy</a></p>
            </Then>
            <Else>
              <Checkbox
                value={false}
                className={cn('checkbox')}
                onChange={val => this.inputValueChanged('terms', val)}
                label={<span>I have read and agree to the <a href='https://soundation.com/terms-of-service' target='_blank'>Terms of Service</a> and <a href='https://soundation.com/privacy-policy' target='_blank'>Privacy Policy</a></span>} />
            </Else>
          </If>
          <If condition={!eduMode}>
            <Checkbox
              value={false}
              className={cn('checkbox')}
              onChange={val => this.inputValueChanged('age', val)}
              label={<span>I am 13 years old or older <br />(or if used within education, I certify that my school has collected my guardians consent)</span>} />
          </If>
          <If condition={!eduMode}>
            <Checkbox
              value={false}
              className={cn('checkbox')}
              onChange={val => this.inputValueChanged('marketing', val)}
              label='I want to receive relevant information and updates' />
          </If>
        </div>
      </div>
    )
  }
}

ConsentsCheckboxes.propTypes = {
  onChange: PropTypes.func,
  eduMode: PropTypes.bool
}

ConsentsCheckboxes.defaultProps = {
  onChange: noop
}

export default ConsentsCheckboxes
