import React from "react";
import PropTypes from "prop-types";
import { Bem } from "@common/utils";
import { isComponent, isEntityMatchingSiteConfig } from "@common/strapi.helpers";
import { PagePreview, PagePreviewComponentId } from "./components/PagePreview";
import { useStrapiContext } from "../../../contexts/StrapiContext";
import { ReadMoreLink } from "../common/ReadMoreLink";
import { VideoPreview } from "./components/VideoPreview";
import "./ComboListBlock.style.scss";

const cn = new Bem({
  name: "combo-list-block",
  prefix: "pfx-",
});

const getPreviewComponent = ({ page, video, name }, config) => {
  if (page?.previews) {
    const { id: pageId, previews = [] } = page;

    const preview = previews
      .filter((p) => isEntityMatchingSiteConfig(p, config))
      .find((data) => isComponent(data, PagePreviewComponentId));

    if (!preview) return {};

    return {
      id: `page_${pageId}`,
      Component: PagePreview,
      props: { ...preview, pageId, title: preview.title || name },
    };
  }

  if (video) {
    return {
      Component: VideoPreview,
      props: { ...video, title: video.name || name },
      id: `video_${video.id}_${name}`,
    };
  }

  return { Component: () => null, props: {} };
};

export const ComboListBlockComponentId = "blocks.combo-list";

const ComboListBlock = ({ title, readMore, items = [] }) => {
  if (!items?.length) return null;
  const { config } = useStrapiContext();

  return (
    <section className={cn()}>
      <div className={cn("title-container")}>
        {title && <h3 className={cn("title")}>{title}</h3>}
        {readMore && <ReadMoreLink {...readMore} />}
      </div>
      <ul className={cn("list")}>
        {items.map((item) => {
          if (!item) return null;

          const { Component, props, id } = getPreviewComponent(item, config);

          if (!Component) return null;

          return (
            <li key={id} className={cn("list-item", { empty: !Component })}>
              {Component ? <Component {...props} /> : null}
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export const ComboListBlockPropTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      page: PropTypes.shape({}),
    })
  ),
  readMore: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    page: PropTypes.shape({}),
  }),
};

ComboListBlock.propTypes = PropTypes.shape(ComboListBlockPropTypes).isRequired;

export default ComboListBlock;
