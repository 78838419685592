import React from 'react'
import PropTypes from 'prop-types'
import { getCtaUrlFromDynamicLink } from '@common/strapi.helpers'
import { Bem } from '@common/utils'
import { useStrapiContext } from '../../../contexts/StrapiContext'
import { CTAButton } from '../../button/cta-button'
import './CtasRowBlock.style.scss'

const cn = new Bem({
  name: 'ctas-row-block',
  prefix: 'pfx-'
})

export const CtasRowBlockId = 'cta.ctas-row'

const CtasRowBlock = ({ ctas, className }) => {
  const { navigation } = useStrapiContext()

  const renderLink = ({ dynamicLink, type, id, size }) => {
    const { links } = dynamicLink
    const [link] = links

    const url = getCtaUrlFromDynamicLink(link, navigation)

    if (!url) return null

    return (
      <CTAButton key={id} size={size} type={type} url={url} text={link.name} />
    )
  }

  return (
    <div className={cn('', null, className)}>
      {ctas.map(renderLink)}
    </div>
  )
}

export const CtasRowBlockPropTypes = {
  ctas: PropTypes.arrayOf({
    id: PropTypes.number,
    size: PropTypes.oneOf(['SMALL', 'MEDIUM', 'LARGE', 'EXTRA_LARGE']),
    type: PropTypes.oneOf(['OUTLINE', 'FULL', 'HOLLOW', 'UNDERLINED_LINK', 'TERTIARY']),
    s_c: PropTypes.object,
    dynamicLink: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  className: PropTypes.string
}

CtasRowBlock.propTypes = PropTypes.shape(CtasRowBlockPropTypes).isRequired

export default CtasRowBlock
