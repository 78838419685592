import { actionTypes } from '@store/actions/types/app'
import { SCREEN_BREAKPOINTS } from '@common/consts'
import { canUseDOM } from '@common/utils'

const getScreenBreakPointIndex = windowSize => {
  const breakpointsCount = SCREEN_BREAKPOINTS.length

  if (!canUseDOM()) {
    // server renders desktop version
    return 0
  }

  for (let i = 0; i < breakpointsCount; i++) {
    if (windowSize >= SCREEN_BREAKPOINTS[i]) {
      return i
    }
  }

  return breakpointsCount
}

export default (state = {
  data: {},
  windowSize: 1600, // Mock value for server side rendering
  windowHeight: 900,
  windowScrollTop: 0,
  screenBreakPointIndex: 0,
  signInRedirectUrl: null,
  mobileLimitationSide: {
    open: false,
    componentName: 'NO_MOBILE_APP'
  },
  mobileDevice: false,
  dragging: false,
  mobileMenuOpen: false,
  halloweekBannerDissmissed: false,
  highDevicePixelRatio: false
}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_UPGRADE_WINDOW_LAST_SHOWN_DATE:
      return {
        ...state,
        data: {
          ...state.data,
          upgradeWindowLastShownDate: action.payload
        }
      }
    case actionTypes.SET_WINDOW_SIZE:
      return {
        ...state,
        windowSize: action.payload.width,
        windowHeight: action.payload.height,
        screenBreakPointIndex: getScreenBreakPointIndex(action.payload.width)
      }
    case actionTypes.SET_WINDOW_SCROLL:
      return {
        ...state,
        windowScrollTop: action.payload
      }
    case actionTypes.SET_SIGN_IN_REDIRECT:
      return {
        ...state,
        signInRedirectUrl: action.payload
      }
    case actionTypes.OPEN_MOBILE_LIMITATION_SIDEBAR:
      return {
        ...state,
        mobileLimitationSide: {
          componentName: action.payload,
          open: true
        }
      }
    case actionTypes.CLOSE_MOBILE_LIMITATION_SIDEBAR:
      return {
        ...state,
        mobileLimitationSide: {
          ...state.mobileLimitationSide,
          open: false
        }
      }
    case actionTypes.SET_MOBILE_DEVICE:
      return {
        ...state,
        mobileDevice: action.payload
      }
    case actionTypes.START_DRAGGING:
      return {
        ...state,
        dragging: true
      }
    case actionTypes.END_DRAGGING:
      return {
        ...state,
        dragging: false
      }
    case actionTypes.OPEN_MOBILE_MENU:
      return {
        ...state,
        mobileMenuOpen: true
      }
    case actionTypes.CLOSE_MOBILE_MENU:
      return {
        ...state,
        mobileMenuOpen: false
      }
    case actionTypes.TOGGLE_MOBILE_MENU:
      return {
        ...state,
        mobileMenuOpen: !state.mobileMenuOpen
      }
    case actionTypes.DISSMISS_HALLOWEEK_BANNER:
      return {
        ...state,
        halloweekBannerDissmissed: true
      };
    case actionTypes.SET_HIGH_DEVICE_PIXEL_RATIO:
      return {
        ...state,
        highDevicePixelRatio: action.payload.value
      }
    default:
      return state
  }
}
