import { put, takeLatest } from "redux-saga/effects";
import { actionTypes } from "@store/actions/types/app";
import createStore from "@store/index";
import { default as localStore } from "store";
import { LOCAL_STORAGE_KEYS } from "../../common/consts";
import { setDocumentScroll } from "@common/utils";
import { closeMobileLimitationSidebar } from "@store/actions/app";

function* onSetSignInRedirect() {
  const reduxStore = createStore();
  const {
    app: { signInRedirectUrl },
  } = reduxStore.getState();
  if (signInRedirectUrl) {
    localStore.set(LOCAL_STORAGE_KEYS.SIGN_IN_REDIRECT_URL, signInRedirectUrl);
  } else {
    localStore.remove(LOCAL_STORAGE_KEYS.SIGN_IN_REDIRECT_URL);
  }
}

function* onSetSignInRedirectSaga() {
  yield takeLatest(actionTypes.SET_SIGN_IN_REDIRECT, onSetSignInRedirect);
}

function* onHandleScroll() {
  yield takeLatest(
    [
      actionTypes.OPEN_MOBILE_MENU,
      actionTypes.CLOSE_MOBILE_MENU,
      actionTypes.TOGGLE_MOBILE_MENU,
      actionTypes.CLOSE_MOBILE_LIMITATION_SIDEBAR,
      actionTypes.OPEN_MOBILE_LIMITATION_SIDEBAR,
    ],
    function* () {
      const reduxStore = createStore();
      const {
        app: { mobileMenuOpen, mobileLimitationSide },
      } = reduxStore.getState();

      if (mobileMenuOpen) {
        setDocumentScroll(false, { onlyMobile: true });
        return;
      }

      if (mobileLimitationSide.open) {
        setDocumentScroll(false);
        return;
      }

      setDocumentScroll(true);
    }
  );
}

export default [onSetSignInRedirectSaga(), onHandleScroll()];
