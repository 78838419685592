'use client'

import React, { useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { Bem } from "@common/utils";
import LazyImage from "@components/lazy-image";
import { getStrapiImage } from "@common/strapi.helpers";
import { AspectRatio } from "@common/imageDimensions";
import YouTubeVideo from "@common/youtube";
import { PlayButton } from "@components/play-button";
import LightboxVideo from "@components/lightbox-video";
import LinesEllipsis from "react-lines-ellipsis";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";
import { getStrapiMediaUrl } from "@common/strapi.helpers";
import "./VideoPreview.style.scss";

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

const cn = new Bem({
  name: "video-preview",
  prefix: "pfx-",
});

const VideoPreview = (props) => {
  const { video, youTubeId, name, title, thumbnail } = props;
  const [isOpen, setIsOpen] = useState(false);
  const onOpen = useCallback(() => setIsOpen(true), []);
  const onClose = useCallback(() => setIsOpen(false), []);

  const { props: videoProps, preview } = useMemo(() => {
    const imgPreview = thumbnail?.url ? getStrapiImage(thumbnail) : null;
    if (youTubeId) {
      const yt = new YouTubeVideo(youTubeId);

      return {
        props: {
          videoUrl: yt.getEmbedUrl(),
        },
        preview: imgPreview || {
          src: yt.getThumbnailUrl(),
          alt: "YouTube video",
          aspectRatio: AspectRatio.fromDimensions({ width: 16, height: 9 }),
        },
      };
    }
    return {
      props: {
        embed: false,
        videoUrl: getStrapiMediaUrl(video),
      },
      preview: imgPreview,
    };
  }, [video, thumbnail, youTubeId]);

  return (
    <div className={cn()}>
      {isOpen ? <LightboxVideo {...videoProps} onClose={onClose} /> : null}
      <div className={cn("wrapper")}>
        <PlayButton
          className={cn("play-button")}
          onClick={onOpen}
          isActive={isOpen}
          theme="dark"
        />

        {preview && (
          <LazyImage
            className={cn("image-wrapper")}
            imgClassName={cn("image")}
            src={preview.src}
            aspectRatio={preview.aspectRatio}
            srcSet={preview.srcSet}
            sources={preview.sources}
            alt={preview.alt}
          />
        )}
      </div>

      <ResponsiveEllipsis
        className={cn("title")}
        component="p"
        text={title || name}
        maxLine={2}
        ellipsis="..."
        trimRight
        basedOn="letters"
      />
    </div>
  );
};

VideoPreview.propTypes = {
  id: PropTypes.number.isRequired,
  video: PropTypes.shape({}),
  description: PropTypes.string,
  thumbnail: PropTypes.shape({}),
  youTubeId: PropTypes.string,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export { VideoPreview };
