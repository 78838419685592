'use client'

import './consents-form.scss'
import PropTypes from 'prop-types'
import { Bem, get } from '@common/utils'
import React from 'react'
import Form from '@components/form/index'
import FormElement from '@components/form-element/index'
import ConsentsCheckboxes from '@components/consents/consents-checkboxes'
import Button from '@components/button/index'
import { CONSENTS } from '@common/validator-models'
import { createUser, updateConsents, logout } from '@common/http'
import { noop } from 'lodash'

const cn = new Bem({
  name: 'consents-form',
  prefix: 'pfx-'
})

class ConsentsForm extends React.Component {
  state = {
    isLoading: false
  };

  getConfirmConsentsFormScheme() {
    return {
      consents: {
        validation: CONSENTS(this.props.eduMode)
      }
    }
  }

  constructor (props) {
    super(props)
  }

  getConsents(form) {
    const { values } = form
    const { eduMode } = this.props

    if (eduMode) {
      return { terms: true }
    } else {
      return {
        age: get(values, 'consents.age', false),
        marketing: get(values, 'consents.marketing', false),
        terms: get(values, 'consents.terms', false)
      }
    }
  }

  static prepareSignUpRequest (form, consents) {
    const { formAuthenticityToken } = form
    return {
      utf8: '✓',
      authenticity_token: formAuthenticityToken,
      consents: consents
    }
  }

  onFinish() {
    const { queryParams } = this.props

    if (queryParams?.closeAfterSubmit) {
      window.close()
    }
  }

  async onFormConfirmConsentsSubmit (form) {
    const { currentUser } = this.props

    if (form.isValid) {
      const consents = this.getConsents(form)

      if (this.props.onAgree !== noop || currentUser) {
        const payload = ConsentsForm.prepareSignUpRequest(form, consents)
        const action = currentUser
          ? updateConsents.bind(null, consents)
          : createUser.bind(null, payload)
        this.setState({ isLoading: true })
        this.props.onAgree(await action())
        this.setState({ isLoading: false })
      }

      this.onFinish()
    }
  }

  async handleLogout () {
    this.props.onLogout(await logout())
    this.onFinish()
  }

  render () {
    const { formAuthenticityToken, currentUser, eduMode } = this.props
    const { isLoading } = this.state

    return (
      <div>
        <Form
          scheme={eduMode ? {} : this.getConfirmConsentsFormScheme()}
          onSubmit={(form) => isLoading || this.onFormConfirmConsentsSubmit(form)}
          className={cn(null, null, this.props.className)}
          formAuthenticityToken={formAuthenticityToken} >
          <FormElement field='consents' className={cn('form-element')}>
            <ConsentsCheckboxes eduMode={eduMode}/>
          </FormElement>
          <FormElement submit className={cn('form-element', 'submit')}>
            <Button
              type='klein-full-no-hover'
              isLoading={ isLoading }
              className={cn('submit')}>Continue</Button>
          </FormElement>
        </Form>
        {
          currentUser &&
          <div className={cn('logout-container')}>
            <a onClick={() => this.handleLogout()} className={cn('logout-link')}>
              I don't agree. Log me out.
            </a>
          </div>
        }
      </div>
    )
  }
}

ConsentsForm.propTypes = {
  currentUser: PropTypes.object,
  eduMode: PropTypes.bool,
  formAuthenticityToken: PropTypes.string,
  queryParams: PropTypes.object,
  className: PropTypes.string,
  onAgree: PropTypes.func,
  onLogout: PropTypes.func
}

ConsentsForm.defaultProps = {
  onAgree: noop,
  onLogout: noop
}

export default ConsentsForm
