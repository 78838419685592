'use client'

import React, { useCallback, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Bem, canUseDOM } from "@common/utils";
import YouTubeVideo from "@common/youtube";
import { getStrapiMediaUrl } from "@common/strapi.helpers";
import "./VideoBlock.style.scss";
import { useEffect } from "react";

const cn = new Bem({
  name: "video-block",
  prefix: "pfx-",
});

export const VideoBlockComponentId = "blocks.video";

const defaultAspectRatio = "16/9";

const getVideoData = ({ videoRelations, videoRelation, file, youtubeId }) => {
  let relations = [];
  let youTubeVideoId = youtubeId;

  const isValidVideoRelation = (options) => {
    const { video } = options || {}
    return !!video?.url;
  };

  const makeFileData = ({ video, fallbackImage } = {}) => {
    return {
      video,
      fallbackImage: fallbackImage?.url ? fallbackImage : undefined,
    };
  };

  const getYouTubeId = (d) => d?.youTubeId || d?.youtubeId;

  youTubeVideoId ||= getYouTubeId(videoRelation);

  if (Array.isArray(videoRelations) && videoRelations.length > 0) {
    const mapped = videoRelations.map(({ videoRelation }) => videoRelation);
    const relationData = mapped.filter(isValidVideoRelation).map(makeFileData);

    const youTubeRelation = mapped.find(getYouTubeId);

    relations.push(...(relationData || []));
    youTubeVideoId ||= getYouTubeId(youTubeRelation);
  }

  if (isValidVideoRelation(videoRelation)) {
    relations.push(makeFileData(videoRelation));
  }

  const fromFile = makeFileData({ video: file });

  if (isValidVideoRelation(fromFile)) {
    relations.push(fromFile);
  }

  return { videos: relations, youTubeVideoId };
};

// Matching 1/16, 16/9, 4.2/3, 3/2, 1/1 for example
const aspectRationRegExp = /[0-9.]*\/[0-9.]*/;
const VideoBlock = (props) => {
  const videoElementRef = useRef(null);
  const [videoMaxWidth, setVideoMaxWidth] = useState(props.videoMaxSize || 0);

  const {
    file,
    youtubeId,
    videoRelation,
    videoRelations = [],
    className,
    allowFullScreen = true,
    settings = {},
    videoMaxSize = false,
  } = props;

  const {
    autoPlay = false,
    aspectRatio = defaultAspectRatio,
    showControls = false,
    loop = false,
  } = settings || {};

  const handleOnLoadedMetaData = useCallback(
    (event) => {
      if (videoMaxSize === true && videoElementRef.current) {
        const { videoWidth } = event.target;
        if (videoWidth) {
          setVideoMaxWidth(videoWidth);
        }
      }
    },
    [videoMaxSize]
  );

  const [numerator, denominator] = useMemo(() => {
    if (!canUseDOM()) return [16, 9];

    const [ar] = aspectRatio?.match(aspectRationRegExp) || [defaultAspectRatio];

    const [n = "1", d = "1"] = (ar || defaultAspectRatio).split("/");

    return [parseFloat(n, 10), parseFloat(d, 10)];
  }, [aspectRatio]);

  useEffect(() => {
    if (typeof videoMaxSize === "number") {
      setVideoMaxWidth(videoMaxSize);
    }
  }, [videoMaxSize]);

  const renderFileVideo = useCallback(
    (videos = []) => {
      const [first] = videos;

      return (
        <video
          ref={videoElementRef}
          onLoadedMetadata={handleOnLoadedMetaData}
          preload={autoPlay ? "auto" : "metadata"}
          className={cn("video", "file")}
          controls={showControls || !autoPlay}
          autoPlay={autoPlay}
          loop={loop}
          muted={autoPlay}
          playsInline={autoPlay}
          src={getStrapiMediaUrl(first)}
        >
          {videos.map(({ video }) => (
            <source
              key={video.id}
              src={getStrapiMediaUrl(video)}
              type={video.mime}
            />
          ))}
          {videos.map(({ fallbackImage }) =>
            fallbackImage ? (
              <img
                key={fallbackImage.id}
                src={getStrapiMediaUrl(fallbackImage)}
                type={fallbackImage.mime}
              />
            ) : null
          )}
        </video>
      );
    },
    [showControls, autoPlay, loop, handleOnLoadedMetaData]
  );

  const renderYouTubeVideo = useCallback(
    (id) => {
      const video = new YouTubeVideo(id);
      return (
        <iframe
          className={cn("video", "youtube")}
          src={video.getEmbedUrl()}
          title="YouTube video player"
          frameBorder="0"
          playlist={loop ? "" : undefined}
          controls={showControls || !autoPlay ? 1 : 0}
          autoPlay={autoPlay ? 1 : 0}
          allowFullScreen={allowFullScreen ? 1 : 0}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        />
      );
    },
    [youtubeId, showControls, allowFullScreen, autoPlay, loop]
  );

  const renderVideo = useCallback(() => {
    const { youTubeVideoId, videos } = getVideoData({
      file,
      youtubeId,
      videoRelation,
      videoRelations,
    });

    if (videos.length > 0) {
      return renderFileVideo(videos);
    }

    if (youTubeVideoId) {
      return renderYouTubeVideo(youTubeVideoId);
    }

    return null;
  }, [
    renderFileVideo,
    renderYouTubeVideo,
    file,
    videoRelations,
    youtubeId,
    videoRelation,
  ]);

  return (
    <div
      className={cn("", "", className)}
      style={{
        "--ar-numerator": numerator,
        "--ar-denominator": denominator,
        "--video-max-width": videoMaxWidth ? `${videoMaxWidth}px` : undefined,
      }}
    >
      {renderVideo()}
    </div>
  );
};

export const VideoBlockPropTypes = {
  file: PropTypes.shape({}),
  youtubeId: PropTypes.shape({}),
  aspectRatio: PropTypes.string.isRequired,
  videoMaxSize: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  videoRelations: PropTypes.arrayOf(PropTypes.shape({})),
  videoRelation: PropTypes.shape({}),
};

VideoBlock.propTypes = PropTypes.shape(VideoBlockPropTypes).isRequired;

export default VideoBlock;
