export default class YouTubeVideo {
  constructor(videoId) {
    this.videoId = videoId
  }

  getThumbnailUrl() {
    return `https://img.youtube.com/vi/${this.videoId}/0.jpg`
  }

  getEmbedUrl() {
    return `https://www.youtube.com/embed/${this.videoId}`
  }

  getRegularUrl() {
    return `https://youtu.be/${this.videoId}`
  }
}
