import { actionTypes } from '@store/actions/types/player'

export default (state = {
  isPlaying: false,
  isPaused: false,
  songUrl: null,
  id: null
}, action) => {
  switch (action.type) {
    case actionTypes.PLAY:
      if (action.payload) {
        return {
          ...state,
          id: action.payload.id || null,
          title: action.payload.title,
          username: action.payload.username,
          duration: Math.floor(action.payload.duration / 1000),
          directWaveformUrl: action.payload.directWaveformUrl || null,
          permalink: action.payload.permalink || null,
          profileLink: action.payload.profileLink || null,
          shortLinkId: action.payload.shortLinkId || null,
          songUrl: action.payload.songUrl,
          doesUserLike: typeof action.payload.doesUserLike === 'boolean' ? action.payload.doesUserLike : null,
          fileName: action.payload.fileName,
          playerId: action.payload.playerId,
          time: action.payload.id === state.id ? state.time : 0,
          isPlaying: true,
          isPaused: false
        }
      } else {
        return {
          ...state,
          isPlaying: true,
          isPaused: false
        }
      }
    case actionTypes.STOP:
      return {
        ...state,
        id: null,
        title: null,
        username: null,
        duration: null,
        directWaveformUrl: null,
        permalink: null,
        profileLink: null,
        shortLinkId: null,
        songUrl: null,
        playerId: null,
        fileName: null,
        time: 0,
        progress: 0,
        isPlaying: false,
        isPaused: false
      }
    case actionTypes.PAUSE:
      return {
        ...state,
        isPlaying: false,
        isPaused: true
      }
    case actionTypes.PROGRESS:
      return {
        ...state,
        time: action.payload.time,
        progress: action.payload.progress
      }
    case actionTypes.FETCHING:
      return {
        ...state,
        fetchProgress: action.payload
      }
    case actionTypes.PROGRESS_CHANGE:
      return {
        ...state,
        time: action.payload
      }
    case actionTypes.LIKE_CONFIRMED:
      if (action.payload === state.id) {
        return {
          ...state,
          doesUserLike: true
        }
      } else {
        return state
      }
    case actionTypes.DISLIKE_CONFIRMED:
      if (action.payload === state.id) {
        return {
          ...state,
          doesUserLike: false
        }
      } else {
        return state
      }
    default:
      return state
  }
}
