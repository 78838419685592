import { actionTypes } from '@store/actions/types/backendData'

export default (state = {
  currentUser: null,
  loading: false,
  error: false,
  acl: {},
  referralReward: 0,
  account: null,
  cancelReasons: [],
  cancelReasonsLoading: false,
  cancelReasonsError: null,
  piecesLoading: false,
  pieces: null,
  piecesError: null,
  moduleName: null,
  controllerName: null,
  actionName: null,
  avatarUrl: null,
  notificationCount: 0
}, action) => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload
      }
    case actionTypes.SET_REFERRAL_REWARD:
      return {
        ...state,
        referralReward: action.payload
      }
    case actionTypes.SET_FEATURE_FLAGS:
      return {
        ...state,
        featureFlags: action.payload
      }
    case actionTypes.CANCEL_SUBSCRIPTION:
      return {
        ...state,
        cancelReasonsLoading: true
      }
    case actionTypes.CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          activeRecurringSubscription: action.payload.type === 'AccountSubscription' ? false : state.currentUser.activeRecurringSubscription,
          subscriptions: state.currentUser.subscriptions.map(item => item.id === action.payload.id ? action.payload : item)
        },
        cancelReasonsLoading: false,
        cancelReasonsError: null
      }
    case actionTypes.CANCEL_SUBSCRIPTION_FAIL:
      return {
        ...state,
        currentUser: {
          ...state.currentUser
        },
        cancelReasonsLoading: false,
        cancelReasonsError: action.payload
      }
    case actionTypes.REMOVE_SUBSCRIPTION_CANCELLATION:
      return {
        ...state,
        loading: true,
        error: false
      }
    case actionTypes.REMOVE_SUBSCRIPTION_CANCELLATION_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          activeRecurringSubscription: action.payload.type === 'AccountSubscription' ? true : state.currentUser.activeRecurringSubscription,
          subscriptions: state.currentUser.subscriptions.map(item => item.id === action.payload.id ? action.payload : item)
        },
        loading: false,
        error: false
      }
    case actionTypes.REMOVE_SUBSCRIPTION_CANCELLATION_FAIL:
      return {
        ...state,
        currentUser: {
          ...state.currentUser
        },
        loading: false,
        error: true
      }
    case actionTypes.SET_ACL:
      return {
        ...state,
        acl: action.payload
      }
    case actionTypes.SET_ACCOUNT_DATA:
      return {
        ...state,
        account: action.payload
      }
    case actionTypes.CANCEL_REASONS:
      return {
        ...state,
        cancelReasonsLoading: true
      }
    case actionTypes.CANCEL_REASONS_SUCCESS:
      return {
        ...state,
        cancelReasonsLoading: false,
        cancelReasons: action.payload
      }
    case actionTypes.CANCEL_REASONS_FAIL:
      return {
        ...state,
        cancelReasonsLoading: false,
        cancelReasonsError: action.payload
      }
    case actionTypes.GET_PIECES: {
      return {
        ...state,
        piecesLoading: true
      }
    }
    case actionTypes.GET_PIECES_SUCCESS: {
      return {
        ...state,
        piecesLoading: false,
        pieces: action.payload.pieces
      }
    }
    case actionTypes.GET_PIECES: {
      return {
        ...state,
        piecesLoading: false,
        piecesError: action.payload.error
      }
    }
    case actionTypes.SET_MODULE_CONTROLLER_ACTION: {
      return {
        ...state,
        moduleName: action.payload.module,
        controllerName: action.payload.controller,
        actionName: action.payload.action
      }
    }
    case actionTypes.SET_AVATAR_URL: {
      return {
        ...state,
        avatarUrl: action.payload.avatarUrl
      }
    }
    case actionTypes.SET_NOTIFICATION_COUNT: {
      return {
        ...state,
        notificationCount: action.payload.notificationCount
      }
    }
    default:
      return state
  }
}
