import { takeLatest } from 'redux-saga/effects'
import { actionTypes } from '@store/actions/types/group'

function * onCreated () {
  yield takeLatest(actionTypes.CREATED, function * (action) {
    if (window.sessionStorage) {
      yield window.sessionStorage.setItem('newGroupId', action.payload.group.id)
    }
    yield window.location.replace(action.payload.group.selfLink)
  })
}

function * onModified () {
  yield takeLatest(actionTypes.MODIFIED, function * (action) {
    yield window.location.replace(action.payload.group.selfLink)
  })
}

function * onDismissSticky () {
  yield takeLatest(actionTypes.DISMISS_STICKY, function * () {
    if (window.sessionStorage) {
      yield window.sessionStorage.removeItem('newGroupId')
    }
  })
}

export default [
  onCreated(),
  onModified(),
  onDismissSticky()
]
