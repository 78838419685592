'use client'
import React from 'react'
import './style.scss'
import PropTypes from 'prop-types'
import { Bem } from '../../common/utils'
import { noop } from 'lodash'
import Icon from '../icon/index'

const cn = new Bem({
  name: 'checkbox',
  prefix: 'pfx-'
})

class Checkbox extends React.Component {

  constructor (props) {
    super(props)
    this.state = {value: props.value}
  }

  componentWillReceiveProps (nextProps) {
    const { value } = nextProps
    this.setState({ value })
  }

  onChange = (event) => {
    const value = event.currentTarget.checked
    this.setState({ value })
    this.props.onChange(value)
  }

  onKeyDown (event) {
    if (event.keyCode === 32) {
      event.stopPropagation()
      event.preventDefault()

      const { value } = this.state
      this.setState({ value: !value })
      this.props.onChange(!value)
    }
  }

  render () {
    const { label, labelClassName, theme = 'classic', size = 'medium' } = this.props
    const { value: checked } = this.state

    const elementsSize = {
      label: size === 'medium' ? '14px' : ' 11px',
      box: size === 'medium' ? '20px' : '12px',
      check: size === 'medium' ? 14 : 8
    }

    return (
      <div className={cn(null, { checked }, this.props.className)} style={{ '--label-size': elementsSize.label, '--box-size': elementsSize.box }}>
        <label className={cn('label')}>
          <input
            type='checkbox'
            checked={checked}
            onChange={this.onChange}
            className={cn('input')}
          />
          <div className={cn('box', theme)} tabIndex={0} onKeyDown={this.onKeyDown.bind(this)}>
            <Icon
              onClick={noop}
              icon='check'
              className={cn('check', null, 'pfx--sound-white')}
              size={elementsSize.check} />
          </div>
          <span className={cn('label-text', null, labelClassName)}>{label}</span>
        </label>
      </div>
    )
  }
}

Checkbox.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  value: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
  theme: PropTypes.oneOf(['classic', 'clean']),
  labelClassName: PropTypes.string
}

Checkbox.defaultProps = {
  onChange: noop,
  value: false
}

export default Checkbox
