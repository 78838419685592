import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Bem } from "@common/utils";
import { useStrapiContext } from "../../../../../contexts/StrapiContext";
import LazyImage from "@components/lazy-image";
import { UIDS } from "@common/strapi.consts";
import { getStrapiImage } from "@common/strapi.helpers";
import LinesEllipsis from "react-lines-ellipsis";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";
import "./PagePreview.style.scss";

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

export const PagePreviewComponentId = "page.page-preview";

const cn = new Bem({
  name: "page-preview",
  prefix: "pfx-",
});

const PagePreview = ({ image, title, pageId }) => {
  const strapiImage = image ? getStrapiImage(image) : null;
  const { navigation } = useStrapiContext();

  const { node } = useMemo(
    () => navigation.searchById({ id: pageId, uids: [UIDS.page] }),
    [navigation, pageId]
  );

  return (
    <a className={cn()} href={navigation.getPath(node)}>
      <div className={cn("wrapper")}>
        {strapiImage && (
          <LazyImage
            className={cn("image-wrapper")}
            imgClassName={cn("image")}
            src={strapiImage.src}
            srcSet={strapiImage.srcSet}
            sources={strapiImage.sources}
            alt={strapiImage.alt}
          />
        )}
      </div>

      <ResponsiveEllipsis
        className={cn("title")}
        component="p"
        text={title}
        maxLine={2}
        ellipsis="..."
        trimRight
        basedOn="letters"
      />
    </a>
  );
};

PagePreview.propTypes = {
  Component: PropTypes.oneOf([PagePreviewComponentId]),
  id: PropTypes.number.isRequired,
  image: PropTypes.shape({}),
  title: PropTypes.string.isRequired,
  pageId: PropTypes.number.isRequired,
};

export { PagePreview };
