// Helpers

var isWebkit = 'WebkitAppearance' in document.documentElement.style && (navigator && !navigator.userAgent.match(/Edge/))

function pad (num, size) {
  var s = '000000000' + num
  return s.substr(s.length - size)
}

function floatToTime (inp) {
  var mins = Math.floor(inp / 60.0)
  var secs = Math.floor(inp - mins * 60)
  return pad(mins, 2) + ':' + pad(secs, 2)
}

HTMLElement.prototype.firstOfClass = function (className) {
  var elements = this.getElementsByClassName(className)
  if (elements.length > 0) return elements[0]
  return null
}

HTMLElement.prototype.hasClass = function (cls) {
  return (' ' + this.className + ' ').indexOf(' ' + cls + ' ') > -1
}

HTMLElement.prototype.removeClass = function(remove) {
  const classes = this.className.split(" ");
  let newClassName = "";

  for(let i = 0; i < classes.length; i++) {
    if(classes[i] !== remove) {
      newClassName += classes[i] + " ";
    }
  }

  this.className = newClassName;
}

HTMLElement.prototype.addClass = function (className) {
  const arr = this.className.split(" ");

  if (arr.indexOf(className) === -1) {
    this.className += " " + className;
  }
}

// TrackPlayer
var players = []
function TrackPlayer (element) {
  var self = this
  if (!element.firstOfClass('audio')) {
    return null
  }
  this.song_url = element.firstOfClass('audio').currentSrc || element.querySelector('source').getAttribute('src')
  let sources = element.querySelectorAll('source')
  this.audio_tag = window.globalPlayer ? window.globalPlayer.audio : element.firstOfClass('audio')

  if (!this.audio_tag) return
  this.play_button = element.firstOfClass('play-pause')
  this.position = element.firstOfClass('position')
  this.bg_container = document.querySelector('.bg-container') || null
  this.bg_position = document.querySelector('.bg-position') || null
  this.waveform = element.firstOfClass('waveform')
  this.progress = element.firstOfClass('progress')
  this.time = element.firstOfClass('time')
  this.like_button = element.firstOfClass('like-button')
  this.share_buttom = element.firstOfClass('share')
  this.share_sheet = element.firstOfClass('share-sheet')
  this.played = false
  this.inc_plays_Url = element.getAttribute('data-plays-inc-url')
  this.id = parseInt(element.getAttribute('data-id'))
  this.title = element.getAttribute('data-title')
  this.artist = element.getAttribute('data-artist')
  this.duration = parseInt(element.getAttribute('data-duration'))
  this.shortLinkId = element.getAttribute('data-short-link-id')
  this.permalink = element.getAttribute('data-permalink')
  this.profileLink = element.getAttribute('data-profile-link')
  this.doesUserLike = element.getAttribute('data-does-user-like') === 'true'
  this.waveform_url = this.waveform.getAttribute('data-image')

  if (this.share_sheet) {
    this.share_sheet_close_button = this.share_sheet.firstOfClass('close')
  }
  if (isWebkit) {
    this.waveform.style.webkitMaskImage = 'url(' + this.waveform_url + ')'
  } else {
    var dpr = 1
    if (window.devicePixelRatio !== undefined) {
      dpr = window.devicePixelRatio
    }
    this.canvas = document.createElement('canvas')
    this.canvas.width = 1024 * dpr
    this.canvas.height = 64 * dpr
    this.waveform.appendChild(this.canvas)
    this.waveform_image = new Image()
    this.waveform_image.onload = function () {
      self.waveformColor = self.getElementColor(self.waveform)
      self.progressColor = self.getElementColor(self.progress)
      self.positionColor = self.getElementColor(self.position)
      self.waveform.style.backgroundColor = 'transparent'
      self.progress.style.display = 'none'
      self.position.style.display = 'none'
      self.drawWaveform()
    }
    this.waveform_image.src = this.waveform_url
  }

  this.getElementColor = function (element) {
    return window.getComputedStyle(element).getPropertyValue('background-color')
  }

  this.drawWaveform = function () {
    if (isWebkit) return
    var p = parseFloat(self.progress.style.width) / 100.0
    var t = parseFloat(self.position.style.width) / 100.0
    var context = self.canvas.getContext('2d')
    context.clearRect(0, 0, self.canvas.width, self.canvas.height)
    context.globalCompositeOperation = 'source-over'
    context.fillStyle = self.waveformColor
    context.fillRect(0, 0, self.canvas.width, self.canvas.height)
    context.fillStyle = self.progressColor
    context.fillRect(0, 0, self.canvas.width * p, self.canvas.height)
    context.fillStyle = self.positionColor
    context.fillRect(0, 0, self.canvas.width * t, self.canvas.height)
    context.globalCompositeOperation = 'destination-in'
    context.drawImage(this.waveform_image, 0, 0, self.canvas.width, self.canvas.height)
  }

  this.updatePlaysCounterOnce = function () {
    if (!self.played) {
      var xhr = new XMLHttpRequest()
      xhr.open('GET', self.inc_plays_Url, true)
      xhr.withCredentials = true
      xhr.send()
      self.played = true
    }
  }

  this.play = function () {
    self.updatePlaysCounterOnce()
    for (var i = 0; i < players.length; i++) {
      var p = players[i]
      if (p != self) {
        p.stop()
      }
    }

    if (window.globalPlayer) {
      window.globalPlayer.play({
        id: this.id,
        title: this.title,
        username: this.artist,
        duration: this.duration,
        directWaveformUrl: this.waveform_url,
        permalink: this.permalink,
        profileLink: this.profileLink,
        shortLinkId: this.shortLinkId,
        songUrl: this.song_url,
        fileName: null,
        doesUserLike: this.doesUserLike
      })

      window.globalPlayer.onLike = (id) => {
        if (id === self.id) {
          self.userDoesLikeTrack()
        }
      }
      window.globalPlayer.onDislike = (id) => {
        if (id === self.id) {
          self.userDoesNotLikeTrack()
        }
      }
    } else {
      self.audio_tag.play()
    }

  }

  this.stop = function() {
    self.unbindCallbacks()
    this.pause();
    (window.globalPlayer ? window.globalPlayer.audio : self.audio_tag).currentTime = 0

    self.position.style.width = '0%'
    if (self.bg_position) {
      self.bg_position.style.width = '0%'
    }
    self.time.innerHTML = '<span>' + floatToTime(0) + '</span><span class="duration"> / ' + floatToTime(self.duration / 1000) + '</span>'
    self.drawWaveform()
    self.play_button.className = 'play-pause play'
  }

  this.pause = function () {
    (window.globalPlayer ? window.globalPlayer : self.audio_tag).pause()
  }

  this.togglePlay = function () {
    if (self.play_button.hasClass('play')) {
      self.play()
    } else {
      self.pause()
    }
  }

  this.isPlayerActive = function() {
    return window.globalPlayer ? window.globalPlayer.id === this.id : true
  }

  this.getOnMouseDownHandlerFor = function (target) {
    return function (e) {
      if (!self.isPlayerActive()) return
      if (self.audio_tag.readyState < 1) return
      var xpos = e.offsetX == undefined ? e.layerX : e.offsetX
      var p = xpos / target.offsetWidth
      self.audio_tag.currentTime = p * self.audio_tag.duration
      target.onmousemove = function (e) {
        var xpos = e.offsetX == undefined ? e.layerX : e.offsetX
        var p = xpos / target.offsetWidth
        self.audio_tag.currentTime = p * self.audio_tag.duration
      }
      target.onmouseup = function (e) {
        target.onmousemove = null
      }
    }
  }

  const progressCallback = function () {
    if (!self.isPlayerActive()) return
    if (self.audio_tag.buffered.length == 0) return
    var p = (self.audio_tag.buffered.end(0) / self.audio_tag.duration)
    self.progress.style.width = p * 100.0 + '%'
    self.drawWaveform()
  }

  const timeUpdateCallback = function () {
    if (!self.isPlayerActive()) return
    var p = self.audio_tag.currentTime / self.audio_tag.duration
    self.position.style.width = p * 100.0 + '%'
    if (self.bg_position) self.bg_position.style.width = p * 100.0 + '%'
    if (self.audio_tag.currentTime && self.audio_tag.duration) {
      self.time.innerHTML = '<span>' + floatToTime(self.audio_tag.currentTime) + '</span><span class="duration"> / ' + floatToTime(self.audio_tag.duration) + '</span>'
    }
    self.drawWaveform()
  }

  const canPlayThroughCallback = function () {
    if (!self.isPlayerActive()) return
    if (self.audio_tag.buffered.length == 0) return
    var p = (self.audio_tag.buffered.end(0) / self.audio_tag.duration)
    self.progress.style.width = p * 100.0 + '%'
    self.drawWaveform()
  }

  const playCallback = function () {
    if (!self.isPlayerActive()) return

    self.unbindCallbacks()
    self.bindCallbacks()
    self.play_button.className = 'play-pause pause'
  }

  const pauseCallback = function () {
    self.play_button.className = 'play-pause play'
  }

  const endedCallback = function () {
    if (!self.isPlayerActive()) return

    self.unbindCallbacks()
    if (players.indexOf(self) + 1 < players.length) {
      var p = players[players.indexOf(self) + 1]
      if (p) p.play()
    }
  }

  self.audio_tag.addEventListener('play', playCallback)

  self.bindCallbacks = function() {
    self.audio_tag.addEventListener('progress', progressCallback)
    self.audio_tag.addEventListener('timeupdate', timeUpdateCallback)
    self.audio_tag.addEventListener('canplaythrough', canPlayThroughCallback)
    self.audio_tag.addEventListener('pause', pauseCallback)
    self.audio_tag.addEventListener('ended', endedCallback)
  }
  self.unbindCallbacks = function() {
    self.audio_tag.removeEventListener('progress', progressCallback)
    self.audio_tag.removeEventListener('timeupdate', timeUpdateCallback)
    self.audio_tag.removeEventListener('canplaythrough', canPlayThroughCallback)
    self.audio_tag.removeEventListener('pause', pauseCallback)
    self.audio_tag.removeEventListener('ended', endedCallback)
  }

  self.play_button.onclick = function () {
    self.togglePlay()
  }

  self.waveform.onmousedown = this.getOnMouseDownHandlerFor(self.waveform)

  if (self.bg_container) {
    self.bg_container.onmousedown = this.getOnMouseDownHandlerFor(self.bg_container)
  }

  if (self.like_button) {
    self.like_button.onclick = function (e) {
      var httpRequest = new XMLHttpRequest()
      httpRequest.open('GET', self.like_button.href)
      httpRequest.send(null)

      var icon = $('i', self.like_button)

      if (!icon.hasClass('icon-new-hearth')) {
        self.userDoesLikeTrack()
      } else {
        self.userDoesNotLikeTrack()
      }
      e.preventDefault()
    }
  }

  self.userDoesLikeTrack = function () {
    self.doesUserLike = true
    if (self.like_button) {
      self.like_button.href = self.like_button.href.split('/like?').join('/unlike?')
      var icon = $('i', self.like_button)
      icon.addClass('icon-new-hearth')
      icon.removeClass('icon-new-hearth-outline')
    }
    window.globalPlayer.likeConfirmed(self.id)
  }

  self.userDoesNotLikeTrack = function() {
    self.doesUserLike = false
    if (self.like_button) {
      self.like_button.href = self.like_button.href.split('/unlike?').join('/like?')
      var icon = $('i', self.like_button)
      icon.addClass('icon-new-hearth-outline')
      icon.removeClass('icon-new-hearth')
    }
    window.globalPlayer.dislikeConfirmed(self.id)
  }

  if (self.share_buttom) {
    self.share_buttom.onclick = function (e) {
      self.share_sheet.style.display = 'block'
      return false
    }
  }

  if (self.share_sheet_close_button) {
    self.share_sheet_close_button.onclick = function (e) {
      self.share_sheet.style.display = 'none'
      return false
    }
  }

  if (window.globalPlayer && !this.audio_tag.paused && this.id === window.globalPlayer.id) {
    self.play_button.className = 'play-pause pause'
    self.bindCallbacks()
  }

}

document.addEventListener('DOMContentLoaded', function (event) {
  var elements = document.getElementsByClassName('player')
  for (var i = 0; i < elements.length; i++) {
    const player = new TrackPlayer(elements[i])
    if (player) {
      players.push(player)
    }
  }
})
