import React from "react";
import PropTypes from "prop-types";
import { Bem } from "@common/utils";
import { isComponent } from "@common/strapi.helpers";
import Badge from "@components/badge";
import { getDisplayPrice } from "@components/price-plans/utils";
import "./PriceBadgeBlock.style.scss";

const cn = new Bem({
  name: "price-badge-block",
  prefix: "pfx-",
});

export const PriceBadgeBlockComponentId = "shared.price-badge";

export const isPriceBadgeBlock = (block = {}) =>
  isComponent(block, PriceBadgeBlockComponentId);

const PeriodMap = {
  monthly: "month",
  yearly: "year",
};

const PriceBadgeBlock = ({ className, price, period, currency }) => {
  const periodText = PeriodMap[period];

  return (
    <Badge className={cn(undefined, undefined, className)} variant="highlight">
      <span>
        {period === "monthly" ? <>From </> : null}
        {getDisplayPrice(price, currency, 0)}{" "}
        {periodText ? `/ ${periodText}` : null}
      </span>
    </Badge>
  );
};

export const PriceBadgeBlockPropTypes = {
  className: PropTypes.string,
  price: PropTypes.number,
  currency: PropTypes.oneOf(["EUR", "USD", "SEK", "GBP"]),
  period: PropTypes.oneOf(["monthly", "yearly"]),
};

PriceBadgeBlock.propTypes = PropTypes.shape(
  PriceBadgeBlockPropTypes
).isRequired;

export default PriceBadgeBlock;
