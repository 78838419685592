import { actionTypes } from '@store/actions/types/modal'
import { uniq } from 'lodash'

export default (state = {
  data: {
    openModalIds: []
  },
  loading: false,
  error: false
}, action) => {
  switch (action.type) {
    case actionTypes.OPEN:
      return {
        ...state,
        data: {
          openModalIds: uniq([...state.data.openModalIds, action.payload])
        }
      }
    case actionTypes.CLOSE:
      return {
        ...state,
        data: {
          openModalIds: state.data.openModalIds.filter(id => id !== action.payload)
        }
      }
    default:
      return state
  }
}
