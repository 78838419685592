import { createTypes } from 'redux-action-creator'

const actionTypes = createTypes([
  'PLAY',
  'STOP',
  'PAUSE',
  'PROGRESS',
  'FETCHING',
  'PROGRESS_CHANGE',
  'LIKE_REQUEST',
  'DISLIKE_REQUEST',
  'LIKE_CONFIRMED',
  'DISLIKE_CONFIRMED'
], 'PLAYER')

export { actionTypes }
