import {
  AuthTypes,
  generateSndConfig,
  SongUtils,
  Soundation4EducationModes
} from "@soundation/song-utils";
import { WEBPACK_DEFINE, LINKS } from "./consts"

export const StudioConfiguration = generateSndConfig({
  authType: AuthTypes.SESSION,
  devMode: WEBPACK_DEFINE.ENV === "development",
  env: WEBPACK_DEFINE.ENV?.toUpperCase(),
  soundation4EducationMode: Soundation4EducationModes.DISABLED,
  urls: {
    rtc: LINKS.RTC_BASE
  }
})

SongUtils.config = StudioConfiguration
