import { all } from 'redux-saga/effects'
import flashMessageSaga from './flashMessage'
import app from './app'
import player from './player'
import group from './group'
import backendData from './backendData'
import campaign from './campaign'

export default function * rootSaga () {
  yield all([
    ...flashMessageSaga,
    ...app,
    ...player,
    ...group,
    ...backendData,
    ...campaign
  ])
}
