import { takeLatest, put } from 'redux-saga/effects'
import { actionTypes } from '@store/actions/types/campaign'
import { setIsHalloweek } from '@store/actions/campaign'

function * onCheckIfHalloweek () {
  yield takeLatest(actionTypes.CHECK_IF_HALLOWEEK, function * () {
    const urlParams = new window.URLSearchParams(window.location.search)
    const halloweekParam = urlParams.get('halloweek')
    if (halloweekParam) {
      yield put(setIsHalloweek(true))
    } else {
      const newYorkTimeZone = {
        offset: -5,
        dstOffset: -4
      }

      const halloweekStart = new Date(Date.UTC(2020, 9, 26, 6 - newYorkTimeZone.dstOffset, 0, 0, 0))
      const halloweekEnd = new Date(Date.UTC(2020, 10, 1, 23 - newYorkTimeZone.offset, 59, 59, 999))

      const now = new Date()
      yield put(setIsHalloweek(now >= halloweekStart && now <= halloweekEnd))
    }
  })
}

export default [onCheckIfHalloweek()]
