import { ENDPOINTS } from '@common/consts'
import { takeLatest, call, put } from 'redux-saga/effects'
import { actionTypes } from '@store/actions/types/backendData'
import {
  cancelSubscription as callCancelSubscription,
  removeSubscriptionCancellation as callRemoveSubscriptionCancellation,
  getCancelReasonTemplates } from '@api/user'
import { setFlashMessages } from '../actions/flashMessage'
import { values, isFunction } from 'lodash'
import JSONAPISerializer from 'jsonapi-serializer'
import {getPiecesFail, getPiecesSuccess} from '../actions/backendData'
import axios from 'axios'

const deserializer = new JSONAPISerializer.Deserializer({
  keyForAttribute: 'camelCase'
})

function * onCancelSubscriptionRequested () {
  yield takeLatest(actionTypes.CANCEL_SUBSCRIPTION, function * (action) {
    try {
      const { subscription } = yield call(callCancelSubscription, action.payload)
      yield put({ type: actionTypes.CANCEL_SUBSCRIPTION_SUCCESS, payload: subscription })
      if (isFunction(action.payload.callback)){
        yield call(action.payload.callback, subscription)
      }
    } catch (e) {
      yield put({ type: actionTypes.CANCEL_SUBSCRIPTION_FAIL })
      yield put(setFlashMessages([
        {
          text: 'Cancellation failed. Please try again or contact <a href="mailto:support@soundation.com">support@soundation.com</a> if the problem persists.',
          type: 'error',
          autoclose: false,
          closeButton: true
        }
      ]))
    }
  })
}

function * onCancelReasonsRequested () {
  yield takeLatest(actionTypes.CANCEL_REASONS, function * (action) {
    try {
      const {data} = yield call(getCancelReasonTemplates, action.payload)
      const deserialize = yield call(deserializer.deserialize, {data: values(data.data)})
      yield put({ type: actionTypes.CANCEL_REASONS_SUCCESS, payload: deserialize })
    } catch (e) {
      yield put({ type: actionTypes.CANCEL_REASONS_FAIL })
      yield put(setFlashMessages([
        {
          text: e,
          type: 'error',
          autoclose: false,
          closeButton: true
        }
      ]))
    }
  })
}

function * onGetPiecesRequested () {
  yield takeLatest(actionTypes.GET_PIECES, function * () {
    try {
      const {data} = yield call(axios.get, ENDPOINTS.PIECES)
      const deserialize = yield call(deserializer.deserialize, data)
      yield put(getPiecesSuccess(deserialize))
    } catch (e) {
      yield put(getPiecesFail(String(e)))
      console.error(e)
    }
  })
}

function * onRemoveSubscriptionCancellationRequested() {
  yield takeLatest(actionTypes.REMOVE_SUBSCRIPTION_CANCELLATION, function * (action) {
    try {
      const { subscription } = yield call(callRemoveSubscriptionCancellation, action.payload)
      yield put({ type: actionTypes.REMOVE_SUBSCRIPTION_CANCELLATION_SUCCESS, payload: subscription })
      yield put(setFlashMessages([
        {
          text: 'Subscription reactivated successfully',
          type: 'notice',
          autoclose: true,
          closeButton: true
        }
      ]))
    } catch (e) {
      yield put({ type: actionTypes.REMOVE_SUBSCRIPTION_CANCELLATION_FAIL, payload: action.payload })
      yield put(setFlashMessages([
        {
          text: 'Reactivate failed. Please try again or contact <a href="mailto:support@soundation.com">support@soundation.com</a> if the problem persists.',
          type: 'error',
          autoclose: false,
          closeButton: true
        }
      ]))
    }
  })
}

export default [
  onCancelSubscriptionRequested(),
  onCancelReasonsRequested(),
  onGetPiecesRequested(),
  onRemoveSubscriptionCancellationRequested()
]
