import { createTypes, async } from 'redux-action-creator'

const actionTypes = createTypes([
  'SET_CURRENT_USER',
  'SET_ACL',
  'SET_ACCOUNT_DATA',
  'SET_MODULE_CONTROLLER_ACTION',
  'SET_AVATAR_URL',
  'SET_NOTIFICATION_COUNT',
  'SET_REFERRAL_REWARD',
  'SET_FEATURE_FLAGS',
  ...async('CANCEL_SUBSCRIPTION'),
  ...async('REMOVE_SUBSCRIPTION_CANCELLATION'),
  ...async('CANCEL_REASONS'),
  ...async('GET_PIECES'),
], 'BACKEND_DATA')

export { actionTypes }
