import { createTypes } from 'redux-action-creator'

const actionTypes = createTypes([
  'UPDATE_UPGRADE_WINDOW_LAST_SHOWN_DATE',
  'SET_WINDOW_SIZE',
  'SET_WINDOW_SCROLL',
  'SET_SIGN_IN_REDIRECT',
  'OPEN_MOBILE_LIMITATION_SIDEBAR',
  'CLOSE_MOBILE_LIMITATION_SIDEBAR',
  'SET_MOBILE_DEVICE',
  'START_DRAGGING',
  'END_DRAGGING',
  'OPEN_MOBILE_MENU',
  'CLOSE_MOBILE_MENU',
  'TOGGLE_MOBILE_MENU',
  'DISSMISS_HALLOWEEK_BANNER',
  'SET_HIGH_DEVICE_PIXEL_RATIO'
], 'APP')

export { actionTypes }
