import { createStore as createReduxStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import Reducers from '@store/reducers'
import { loadState, saveState } from '@store/localStorage'
import { canUseDOM } from '@common/utils'

import { throttle, merge, once } from 'lodash'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './sagas/index'

import { actionTypes } from '@store/actions/types/user'
import { closeMobileMenu } from './actions/app'

const sagaMiddleware = createSagaMiddleware()

const watch = (getState, key) => {
  let currentValue = getState()[key]
  return callback => () => {
    let newValue = getState()[key]
    if (currentValue !== newValue) {
      let oldValue = currentValue
      currentValue = newValue
      callback(newValue, oldValue, key)
    }
  }
}

const subscribe = watcher => throttle(watcher((newVal, oldVal, key) => {
  if (newVal !== oldVal) {
    saveState({ app: newVal })
  }
}), 1000)

// Very nasty fix, but currently createStore is fired for each component wrapped with createProvider.
// Each of this call can create store and populate store with initialState based on BE ruby vars passed via props.
// Unfortunatly afters store is created for the first time - it's not updated with any of those vars.
// And sice store is created on app init -> ruby vars are never passed as initial state.
const setDefaultUserData = once((currentUser) => {
  window.store.dispatch({ type: actionTypes.SET_DATA, payload: currentUser })
})

const createStore = initialState => {
  if (!canUseDOM()) {
    return createReduxStore(Reducers, initialState, applyMiddleware(sagaMiddleware, thunkMiddleware))
  } else {
    const middlewares = [
      sagaMiddleware,
      thunkMiddleware
    ]

    window.state = !window.state ? merge({}, loadState(), initialState) : merge(window.state, initialState)

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    if (!window.store) {
      const store = createReduxStore(
        Reducers,
        window.state,
        composeEnhancers(applyMiddleware(...middlewares))
      )
      sagaMiddleware.run(rootSaga)
      store.subscribe(subscribe(watch(store.getState, 'app')))
      window.store = store
      store.dispatch(closeMobileMenu())
    }

    if (window.state.currentUser) {
      // If initialState has current user - populate store data
      setDefaultUserData(window.state.currentUser)
    }

    return window.store
  }
}

export default createStore
