import { takeLatest, put } from 'redux-saga/effects'
import { actionTypes } from '@store/actions/types/player'
import createStore from '@store/index'
import { canUseDOM } from '../../common/utils'
import { noop } from 'lodash'
import { play, pause, progress, fetching, likeConfirmed, dislikeConfirmed } from '../actions/player'
import { dislikeTrack, incrementPlays, likeTrack } from '../../common/http'

let audio, interval

// Serve side safety
if (canUseDOM()) {

  window.globalPlayer = {
      get audio () { return audio },
      get id() {
        const store = createStore()
        const state = store.getState()
        return state.player.id
      },
      play: (data) => {
        const store = createStore()
        store.dispatch(play(data))
      },
      pause: () => {
        const store = createStore()
        store.dispatch(pause())
      },
      likeConfirmed: (trackId) => {
        const store = createStore()
        store.dispatch(likeConfirmed(trackId))
      },
      dislikeConfirmed: (trackId) => {
        const store = createStore()
        store.dispatch(dislikeConfirmed(trackId))
      },
      onLike: (trackId) => {},
      onDislike: (trackId) => {}
  }

  audio = new Audio()

  audio.addEventListener('play', () => {
    interval = setInterval(() => {
      const store = createStore()
      const state = store.getState().player

      if (state.time !== audio.currentTime ) {
        store.dispatch(progress({
          time: audio.currentTime,
          progress: audio.duration ? audio.currentTime / audio.duration : 0
        }))
      }
    }, 40)
  })

  audio.addEventListener('ended', () => {
    const store = createStore()
    store.dispatch(pause())
    store.dispatch(progress({time: 0, progress: 0}))
  })

  audio.addEventListener('pause', () => {
    clearInterval(interval)
  })
  audio.addEventListener('loadeddata', () => {
    const store = createStore()
    store.dispatch(fetching(1))
  })
  audio.addEventListener('progress', () => {
    const store = createStore()
    store.dispatch(fetching(audio.buffered.end(0) / audio.duration))
  })
} else {
  audio = {
    play: noop,
    stop: noop,
    pause: noop
  }
}

function * onPlayerPlay () {
  yield takeLatest(actionTypes.PLAY, () => {
    const store = createStore()
    const state = store.getState()
    const { songUrl, time, id } = state.player
    if (audio.src !== songUrl) {
      audio.src = songUrl
      if (id) {
        incrementPlays(id)
      }
    }
    audio.currentTime = time
    audio.play()
  })
}

function * onPlayerStop () {
  yield takeLatest(actionTypes.STOP, () => {
    audio.pause()
    audio.currentTime = 0
  })
}

function * onPlayerPause () {
  yield takeLatest(actionTypes.PAUSE, () => {
    audio.pause()
  })
}

function * onPlayerProgressChange () {
  yield takeLatest(actionTypes.PROGRESS_CHANGE, () => {
    const store = createStore()
    const state = store.getState()
    const { time } = state.player
    audio.currentTime = time
    if (audio.paused) {
      store.dispatch(progress({
        time: audio.currentTime,
        progress: audio.currentTime / audio.duration
      }))
    }
  })
}

function * onPlayerLikeTrack () {
  yield takeLatest(actionTypes.LIKE_REQUEST, function * () {
    const store = createStore()
    const state = store.getState()
    const { id } = state.player

    yield likeTrack(id)
    yield put({ type: actionTypes.LIKE_CONFIRMED, payload: id })
    if (canUseDOM()) {
      window.globalPlayer.onLike(id)
    }
  })
}

function * onPlayerDislikeTrack () {
  yield takeLatest(actionTypes.DISLIKE_REQUEST, function * () {
    const store = createStore()
    const state = store.getState()
    const { id } = state.player

    yield dislikeTrack(id)
    yield put({ type: actionTypes.DISLIKE_CONFIRMED, payload: id })
    if (canUseDOM()) {
      window.globalPlayer.onDislike(id)
    }
  })
}

export default [
  onPlayerPlay(),
  onPlayerStop(),
  onPlayerPause(),
  onPlayerProgressChange(),
  onPlayerLikeTrack(),
  onPlayerDislikeTrack()
]
