import { combineReducers } from 'redux'
import { actionTypes } from '@store/actions/types/analytics'

const dataLayerReducer = (state = {
  data: {},
  loading: false,
  error: false
}, action) => {
  switch (action.type) {
    case actionTypes.LOAD_DATA_LAYER:
      return {
        ...state,
        loading: true,
        error: false
      }
    case actionTypes.LOAD_DATA_LAYER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false
      }
    case actionTypes.LOAD_DATA_LAYER_FAIL:
      return {
        ...state,
        loading: false,
        error: true
      }
    default:
      return state
  }
}

const gtmSettingsReducer = (state = {
  data: {},
  loading: false,
  error: false
}, action) => {
  switch (action.type) {
    case actionTypes.LOAD_GTM_SETTINGS:
      return {
        ...state,
        loading: true,
        error: false
      }
    case actionTypes.LOAD_GTM_SETTINGS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false
      }
    case actionTypes.LOAD_GTM_SETTINGS_FAIL:
      return {
        ...state,
        loading: false,
        error: true
      }
    default:
      return state
  }
}

export default combineReducers({
  dataLayer: dataLayerReducer,
  gtmSettings: gtmSettingsReducer
})
