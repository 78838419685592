import { actionTypes } from '@store/actions/types/group'
import { canUseDOM } from '@common/utils'

export default (state = {
  newGroupId: canUseDOM() && window.sessionStorage && parseInt(window.sessionStorage.getItem('newGroupId'), 10) || null
}, action) => {
  switch (action.type) {
    case actionTypes.CREATED:
      return {
        ...state,
        newGroupId: action.payload.group.id
      }
    case actionTypes.DISMISS_STICKY:
      return {
        ...state,
        newGroupId: null
      }
    default:
      return state
  }
}
