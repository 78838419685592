import { actionTypes } from '@store/actions/types/flashMessage'
import { isUndefined } from 'lodash'

export default (state = {
  text: null,
  type: null,
  componentName: null,
  autoclose: true,
  closeButton: false,
  messages: []
}, action) => {
  const { payload } = action
  let { text, type, componentName, autoclose, closeButton } = payload || {}

  if (isUndefined(autoclose)) {
    autoclose = true
  }

  switch (action.type) {
    case actionTypes.SET_MESSAGES:
      return {
        ...state,
        messages: payload
      }

    case actionTypes.SHIFT_MESSAGE:
      return {
        ...state,
        messages: state.messages.slice(1)
      }

    case actionTypes.SET_BY_VALUES:
      return {
        ...state,
        componentName: null,
        text,
        type,
        autoclose,
        show: text && type,
        closeButton: isUndefined(closeButton) ? !autoclose : closeButton
      }
    case actionTypes.SHOW:
      return {
        ...state,
        show: true
      }
    case actionTypes.HIDE:
      return {
        ...state,
        show: false
      }
    case actionTypes.SET_BY_COMMON_COMPONENT:
      return {
        ...state,
        componentName,
        text: null,
        type: null,
        autoclose,
        show: !!componentName,
        closeButton: isUndefined(closeButton) ? !autoclose : closeButton
      }
    default:
      return state
  }
}
