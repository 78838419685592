import { combineReducers } from 'redux'
import analyticsReducer from './analytics'
import appReducer from './app'
import modalReducer from './modal'
import userReducer from './user'
import flashMessageReducer from './flashMessage'
import backendDataReducer from './backendData'
import playerReducer from './player'
import groupReducer from './group'
import campaignReducer from './campaign'

export default combineReducers({
  analytics: analyticsReducer,
  app: appReducer,
  modal: modalReducer,
  currentUser: userReducer,
  flashMessage: flashMessageReducer,
  backendData: backendDataReducer,
  player: playerReducer,
  group: groupReducer,
  campaign: campaignReducer
})
