'use client'
import React from 'react'
import './style.scss'
import PropTypes from 'prop-types'
import { Bem } from '../../common/utils'
import { If } from 'react-if'
import { first, isArray, noop } from 'lodash'
import validate from 'validate.js'

const cn = new Bem({
  name: 'form-element',
  prefix: 'pfx-'
})

// Must be passed uncontrolled components which control their own state as it doesn't update the state value pased to its cloned children
class FormElement extends React.Component {
  constructor (props) {
    super(props)
    let { children, formRef } = this.props
    const eventsMiddleware = {
      onChange: this._onChange.bind(this),
      onFocus: this._onFocus.bind(this),
      onBlur: this._onBlur.bind(this)
    }

    this.state = {}
    if (props.error) {
      this.state.lastValidation = [props.error]
    }

    if (isArray(children)) {
      children = first(children)
    }

    const childPros = Object.assign({}, children.props, eventsMiddleware)
    this.wrappedChild = React.cloneElement(children, childPros, children.props.children)

    if (formRef) {
      const formOnSubmit = formRef._onSubmit
      formRef._onSubmit = (...args) => {
        this.validate(this.state.value)
        formOnSubmit(...args)
      }
    }
  }

  componentDidMount () {
    const { value, defaultValue } = this.wrappedChild.props
    const res = defaultValue || value
    this.setState({ value: res })
    this._onChange(res)
  }

  componentDidUpdate (prevProps) {
    if (prevProps.error !== this.props.error) {
      if (this.props.error) {
        this.setState({ lastValidation: [this.props.error] })
      } else {
        this.validate(this.state.value)
      }
    }
  }

  renderInfoContainer () {
    let submit = this.isFormSubmit()
    return (
      <If condition={this.hasError() && submit && this.props.showErrors}>
        <div className={cn('text-container', { 'error': this.hasError(), 'small-error': this.hasError() && this.props.errorSize === 'small', submit })} > { this.getFirstError() } </div>
      </If>
    )
  }

  getFirstError () {
    return first(this.state.lastValidation)
  }

  hasError () {
    return !!(this.state.lastValidation && this.state.lastValidation.length)
  }

  isFormSubmit () {
    const { formRef } = this.props
    let isSubmit = true

    if (formRef) {
      isSubmit = formRef.isSubmit()
    }

    return isSubmit
  }

  validate (value) {
    const { include, formRef, field, validation } = this.props

    if (typeof value === 'undefined') {
      value = this.getValue()
    }

    const validationCurrentValue = {
      [field]: value
    }
    const validationSettings = {
      [field]: validation
    }

    if (include && formRef) {
      const formValidationSettings = formRef.getValidationSettings()
      const formValidationValues = formRef.getFormPublicState().values

      include.forEach((extraField) => {
        validationSettings[extraField] = formValidationSettings[extraField]
        validationCurrentValue[extraField] = formValidationValues[extraField]
      })
    }

    const isValid = validate(validationCurrentValue, validationSettings) || {}

    return this.setState({
      value,
      lastValidation: isValid[field]
    })
  }

  getValue () {
    return this.state.value
  }

  _onChange (value) {
    this.setState({ value }, () => {
      this.validate(value)
      this.props.onChange(value)
    })
  }

  _onFocus (event) {
    this.props.onFocus(event)
  }

  _onBlur (event) {
    this.props.onBlur(event)
  }

  render () {
    return (
      <div
        onClick={this.props.onClick}
        className={cn(null, { error: this.hasError() && this.props.showErrors, submit: this.isFormSubmit() }, this.props.className)}>
        { this.wrappedChild }
        { this.renderInfoContainer() }
      </div>
    )
  }
}

FormElement.propTypes = {
  children: PropTypes.any, // supported child: Checkbox, TextField
  validation: PropTypes.any, // validate.js object, key should match field name
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  field: PropTypes.string,
  submit: PropTypes.bool,
  className: PropTypes.string,
  include: PropTypes.arrayOf(PropTypes.string),
  formRef: PropTypes.any,
  isLoading: PropTypes.bool,
  showErrors: PropTypes.bool,
  errorSize: PropTypes.oneOf(['small', 'medium'])
}

FormElement.defaultProps = {
  onChange: noop,
  onFocus: noop,
  onBlur: noop,
  onClick: noop,
  isLoading: false,
  showErrors: true,
  errorSize: 'medium'
}

FormElement.displayName = 'FormElement'

export default FormElement
