import axios from 'axios'
import { LINKS } from '@common/consts'
import { gotoLocation, unescapeHtml } from '@common/utils'
import mapKeysDeep from 'map-keys-deep-lodash'
import { camelCase } from 'lodash'

const defaultOptions = {
  transformResponse: [function (data) {
    let response
    try {
      response = mapKeysDeep(JSON.parse(unescapeHtml(data)), (val, key) => camelCase(key))
    } catch (e) {
      response = data
    }
    return response
  }]
}

const instance = axios.create(defaultOptions)
instance.interceptors.response.use(null, error => {
  if (error.response.status === 401) {
    gotoLocation(LINKS.AUTH)
    return error
  }
  throw error
})

export default instance
