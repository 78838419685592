import { actionTypes } from '@store/actions/types/user'

export default (state = {
  data: {},
  loading: false,
  error: false
}, action) => {
  switch (action.type) {
    case actionTypes.SET_DATA:
      return {
        ...action.payload,
        data: {
          ...state.data,
          ...action.payload.data
        }
      }
    case actionTypes.CANCEL_SUBSCRIPTION:
      return {
        ...state,
        data: {
          ...state.data,
          activeRecurringSubscription: true
        },
        loading: true,
        error: false
      }
    case actionTypes.CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          activeRecurringSubscription: false
        },
        loading: false,
        error: false
      }
    case actionTypes.CANCEL_SUBSCRIPTION_FAIL:
      return {
        ...state,
        data: {
          ...state.data,
          activeRecurringSubscription: true
        },
        loading: false,
        error: true
      }
    default:
      return state
  }
}
