import '@styles/index.scss'
import './serverPolyfills.js'
import * as ReactRailsUJS from './react-rails-ujs/index.jsx'
import * as ReactDOM from 'react-dom'
import React from 'react'
import { assignToGlobalScope } from '@common/utils'
import './global-events-bindings.jsx'

assignToGlobalScope({ ReactDOM })
assignToGlobalScope({ React })
assignToGlobalScope({ ReactRailsUJS })
