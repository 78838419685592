import React from "react";
import PropTypes from "prop-types";
import { Bem } from "@common/utils";
import { getCtaUrlFromDynamicLink } from "@common/strapi.helpers";
import RenderMarkdown from "@components/render-markdown";
import { SIZE } from "@components/linkton";

import { CTAButton } from "../../button/cta-button";
import { useStrapiContext } from "../../../contexts/StrapiContext";

import "./CopyCtaBlock.style.scss";

const cn = new Bem({
  name: "copy-cta-block",
  prefix: "pfx-",
});

const attributes = {
  h1: {
    className: cn("title"),
  },
  h2: {
    className: cn("title"),
  },
  h3: {
    className: cn("title"),
  },
  p: {
    className: cn("text"),
  },
};

const CopyCtaBlock = ({
  text,
  className,
  textContainerClassName,
  linksContainerClassName,
  dynamicLinks = [],
  align = "center",
  linkType = "full",
}) => {
  const { navigation } = useStrapiContext();

  const renderLink = ({ links }) => {
    const [link] = links;
    const url = getCtaUrlFromDynamicLink(link, navigation);
    if (!url) return null;

    return (
      <CTAButton
        size={SIZE.LARGE}
        type={linkType}
        url={url}
        text={link.name}
      />
    );
  };

  return (
    <div className={cn(undefined, { [`align-${align}`]: true }, className)}>
      <div className={cn("text-container", undefined, textContainerClassName)}>
        <RenderMarkdown markdownContent={text || ""} attributes={attributes} />
      </div>
      {dynamicLinks.length > 0 && (
        <div className={cn("links", undefined, linksContainerClassName)}>
          {dynamicLinks.map((data) => (
            <React.Fragment key={data.id}>{renderLink(data)}</React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export const CopyCtaBlockkPropTypes = {
  // Markdown
  text: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
  textContainerClassName: PropTypes.string,
  align: PropTypes.oneOf(["left", "center", "right"]),
  linkType: PropTypes.oneOf(["full", "outline", "hollow", "underline"]),
};

CopyCtaBlock.propTypes = PropTypes.shape(CopyCtaBlockkPropTypes).isRequired;

export default CopyCtaBlock;
