import { Component } from 'react'
import BEM from 'react-bem-helper'
import PropTypes from 'prop-types'
import './style.scss'
import VideoPlayer from "../video-player";
import Linkton, {COLOR, SIZE, TYPE} from "../linkton";
import {preventScroll} from "../../common/preventScroll";

const bem = BEM({
  prefix: 'pfx-',
  name: 'lightbox-video'
})

class LightboxVideo extends Component {
  static propTypes = {
    videoUrl: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  }

  handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      this.props.onClose()
    }
  }

  componentDidMount() {
    document.body.addEventListener('keydown', this.handleKeyDown)
    preventScroll(true)
  }

  componentWillUnmount() {
    document.body.removeEventListener('keydown', this.handleKeyDown)
    preventScroll(false)
  }

  onOverlayClick = (e) => {
    e.stopPropagation()
    this.props.onClose()
  }

  render() {
    return (
      <div {...bem('overlay')} onClick={this.onOverlayClick}>
        <div {...bem('player')}>
          <div {...bem('close-container')}>
            <Linkton type={TYPE.HOLLOW} color={COLOR.WHITE} size={SIZE.LARGE} padding={0}>
              <i className='icon-close' />
            </Linkton>
          </div>
          <VideoPlayer videoUrl={this.props.videoUrl} />
        </div>
      </div>
    )
  }
}

export default LightboxVideo
