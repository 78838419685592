const LOCAL_STORAGE_PREFIX = 'SND_STORE'

const loadState = () => {
  try {
    const {localStorage} = window

    if(localStorage) {
      const serializedState = localStorage.getItem(LOCAL_STORAGE_PREFIX)
      return serializedState ?
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_PREFIX))
        : {}
    }

    return {}

  } catch (error) {
    console.error('An error occurs during reading state from local storage', error)
    return {}
  }
}

const saveState = state => {
  try {
    const {localStorage} = window
    if (localStorage) {
      const serializedState = JSON.stringify(state)
      localStorage.setItem(LOCAL_STORAGE_PREFIX, serializedState)
    }
  } catch (error) {
    console.error('An error occurs during saving state to local storage', error)
  }
}

export {
  loadState,
  saveState
}
