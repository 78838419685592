import { put, takeLatest, delay } from 'redux-saga/effects'
import { actionTypes } from '@store/actions/types/flashMessage'
import createStore from '@store/index'

function * onMessageShow () {
  const store = createStore()
  const { flashMessage } = store.getState()

  if (flashMessage.autoclose) {
    yield delay(10000)
    yield put({ type: actionTypes.HIDE })
  } else {
    yield put({ type: actionTypes.SET_AUTOCLOSE, payload: false })
  }
}

function * displayNextMessage () {
  const store = createStore()
  const { messages } = store.getState().flashMessage
  const message = messages && messages[0]
  if (message) {
    yield put({type: actionTypes.SET_BY_VALUES, payload: message})
    yield put({type: actionTypes.SHIFT_MESSAGE })
  }
}

function * onMessageHide () {
  yield displayNextMessage()
}

function * onAutocloseSet () {
  const store = createStore()
  const { flashMessage } = store.getState()

  if (flashMessage.autoclose) {
    yield delay(7000)
    yield put({ type: actionTypes.HIDE })
  }
}

function * onShowSaga () {
  yield takeLatest(actionTypes.SHOW, onMessageShow)
}

function * onSetSaga () {
  yield takeLatest(actionTypes.SET_BY_VALUES, onMessageShow)
}

function * onSetComponentSaga () {
  yield takeLatest(actionTypes.SET_BY_COMMON_COMPONENT, onMessageShow)
}

function * onHideSaga () {
  yield takeLatest(actionTypes.HIDE, onMessageHide)
}

function * onSetAutocloseSaga () {
  yield takeLatest(actionTypes.SET_AUTOCLOSE, onAutocloseSet)
}

function * onSetMessages () {
  yield takeLatest(actionTypes.SET_MESSAGES, displayNextMessage)
}

export default [
  onShowSaga(),
  onSetSaga(),
  onSetComponentSaga(),
  onHideSaga(),
  onSetAutocloseSaga(),
  onSetMessages(),
]
