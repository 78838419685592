import { actionTypes } from '@store/actions/types/campaign';

export default (
  state = {
    isHalloweek: false,
  },
  action
) => {
  switch (action.type) {
    case actionTypes.SET_IS_HALLOWEEK:
      return {
        ...state,
        isHalloweek: action.payload.isHalloweek,
      };
    default:
      return state;
  }
};
