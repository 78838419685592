'use client'
import React from 'react'
import PropTypes from 'prop-types'
import { Bem, get } from '../../common/utils'
import { noop, pick, uniqueId, pickBy, isObject } from 'lodash'
import validate from 'validate.js'
import Children from 'react-children-utilities'

const cn = new Bem({
  name: 'form',
  prefix: 'pfx-'
})

class Form extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isDirty: false,
      isSubmit: false
    }

    this.childRefs = []
    this.fields = []

    this._onSubmit = this._onSubmit.bind(this)
    this._onChange = this._onChange.bind(this)
    this.setChildren()
  }

  componentDidMount () {
    this.setInitialState()
  }

  isSubmit () {
    return this.state.isSubmit
  }

  isDirty () {
    return this.state.isDirty
  }

  getValidationSettings () {
    const validationSettings = this.wrapperChildren.reduce((memo, child) => {
      const { field, validation } = child.props
      if (field) {
        return Object.assign(memo, { [field]: validation })
      } else {
        return memo
      }
    }, {})

    return pickBy(validationSettings)
  }

  setInitialState () {
    const state = this.wrapperChildren.reduce((memo, child) => {
      const { field, children } = child.props

      if (field && children.props) {
        return Object.assign(memo, { [field]: children.props.value })
      } else {
        return memo
      }
    }, {})

    state.isValid = this.isValid()
    this.setState(state)
  }

  _onSubmit () {
    this.validate()
    this.setState({ isSubmit: true }, () => this.props.onSubmit(this.getFormPublicState()))
  }

  _onChange () {
    const isValid = this.isValid()
    this.setState({ isValid }, () => this.props.onChange(this.getFormPublicState()))
  }

  validate () {
    return validate(this.getFormPublicState().values, this.getValidationSettings())
  }

  isValid () {
    return !this.validate()
  }

  componentWillReceiveProps (nextProps) {
    this.setChildren(nextProps.children)
  }

  setChildren (children = this.props.children) {
    if (isObject(children)) children = [children]
    this.childRefs = []

    this.wrapperChildren = Children.deepMap(children, (child) => {
      const field = this.props.scheme[get(child, 'props.field')] || {}
      const validation = field.validation
      let extendedProps = {}

      if (child.type && child.type.displayName === 'FormElement') {
        extendedProps = {
          validation,
          key: child.props.field || uniqueId(),
          onChange: (value) => {
            if (child.props.onChange) {
              child.props.onChange(value)
            }

            if (child.props.field) {
              this.setState({ [child.props.field]: value, isDirty: true }, () => {
                this._onChange()
              })
            }
          },
          formRef: this,
          ref: (r) => this.childRefs.push(r)
        }

        if (child.props.submit) {
          extendedProps.onClick = (event) => {
            event.preventDefault()
            this._onSubmit()
          }
        }

        this.fields.push(child.props.field)
      }

      return React.cloneElement(child, Object.assign({}, child.props, extendedProps), get(child, 'props.children'))
    })
  }

  getFormPublicState () {
    const { isValid, isDirty, isSubmit } = this.state
    const { formAuthenticityToken } = this.props
    return {
      values: pick(this.state, this.fields),
      isValid,
      isDirty,
      isSubmit,
      formAuthenticityToken
    }
  }

  renderChildren () {
    return this.wrapperChildren
  }

  render () {
    const { isValid, isDirty, isSubmit } = this.state

    const modifiers = {
      dirty: isDirty,
      valid: isValid,
      submit: isSubmit
    }

    return (
      <form ref="form" className={cn(null, modifiers, this.props.className)} onSubmit={(e) => {
        e.preventDefault()
        this._onSubmit()
      }}>
        { this.renderChildren() }
      </form>
    )
  }

  reset () {
    this.refs.form.reset()
  }

}

Form.propTypes = {
  scheme: PropTypes.objectOf(PropTypes.shape({
    validation: PropTypes.any
  })),
  children: PropTypes.any,
  formAuthenticityToken: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  className: PropTypes.string,
  isLoading: PropTypes.bool
}

Form.defaultProps = {
  onChange: noop,
  onSubmit: noop,
  isLoading: false
}

export default Form
