import {canUseDOM} from "./utils";

export const preventScroll = (prevent) => {
  if (!canUseDOM()) return
  if (prevent) {
    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.position = 'fixed';
    document.body.style.overflowY = 'scroll';
  } else {
    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    document.body.style.overflowY = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  }
}
