import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Bem } from "@common/utils";
import { UIDS } from "@common/strapi.consts";
import { useStrapiContext } from "../../../../contexts/StrapiContext";
import Linkton, { SIZE, TAG, TYPE } from "@components/linkton";
import "./ReadMoreLink.style.scss";

const cn = new Bem({
  name: "read-more-link",
  prefix: "pfx-",
});

const ReadMoreLink = ({ name, page }) => {
  const { navigation } = useStrapiContext();

  const readMoreItem = useMemo(() => {
    if (!page) return null;

    const { node } = navigation.searchById({ id: page.id, uids: [UIDS.page] });

    if (!node) return null;

    const text = name || page.name;

    return {
      link: navigation.getPath(node),
      text,
    };
  }, [name, navigation, page]);

  return readMoreItem ? (
    <Linkton
      className={cn()}
      size={SIZE.MEDIUM}
      type={TYPE.TERTIARY}
      tag={TAG.ANCHOR}
      href={readMoreItem.link}
    >
      {readMoreItem.text}
    </Linkton>
  ) : null;
};

ReadMoreLink.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  page: PropTypes.shape({}),
};

export { ReadMoreLink };
