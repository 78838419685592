import { useCallback } from "react";

export const useEmbedStudio = () => {
  const openStudioModal = useCallback((templateLink) => {
    try {
      window.open(
        templateLink,
        "_blank"
      );
    } catch {
      console.error("Soundation: Embed Studio is not initialized");
    }
  }, []);

  return { openStudioModal };
};
