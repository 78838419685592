import MobileDetect from 'mobile-detect'
import { canUseDOM } from './utils'

const FAKE_SERVER_USER_AGENT = 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_12_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/61.0.3163.100 Safari/537.36'
let mobileDetect

if (canUseDOM()) {
  mobileDetect = new MobileDetect(window.navigator.userAgent)
} else {
  mobileDetect = new MobileDetect(FAKE_SERVER_USER_AGENT)
}

export default mobileDetect
